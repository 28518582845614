import React from 'react';
import App from 'Src/app/';
import Main from 'Components/Main';
import Display from 'Components/Display';

const EnglishPage = () => {
  return (
    <App lang="en">
      <Display>
        <Main />
      </Display>
    </App>
  );
};

export default EnglishPage;
